import React, { Component } from 'react';
import { Button, Layout, Alert, Select, Menu, Drawer, Space, notification, Card } from 'antd';
import { ListGroup, Row, Col, Container, Image } from 'react-bootstrap';
import CompanyDetails from "./company";
import SEODetails from "./seo";
import PaymentDetails from "./payment";
import FBChatDetails from "./fbchat";
import MenuItems from "./menuitems";
import DragListViewCategoryItems from "./dragcategories";
import DragListViewMenuItems from "./dragmenuitems";
import Box from '@material-ui/core/Box';
import axios from 'axios';
import config from 'react-global-configuration';
import UploadItems from "./imageupload-global";
import PImage from "./preview/image.component";
import Products from "./products-home";
import ReactPaginate from 'react-paginate';
import copy from "copy-to-clipboard";

import {
  BarcodeOutlined,
  AppstoreAddOutlined,
  FormatPainterOutlined,
  DesktopOutlined,
  LoadingOutlined,
  ToolOutlined,
  ReadOutlined,
  SlidersOutlined,
  FileImageOutlined,
  VideoCameraAddOutlined,
  PushpinOutlined 

} from '@ant-design/icons';
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const { Sider } = Layout;
const { SubMenu } = Menu;
const { Option } = Select;



export default class header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menus: [], images: [], loaded: false
    };
  }


  state = {
    collapsed: false,
  };

  onCollapse = collapsed => {
    this.setState({ collapsed });
  };



  componentDidMount() {
    axios.get(config.get('backendURL') + 'menus/' + sessionStorage.getItem('company'))
      .then(response => {
        this.setState({ menus: response.data })
      })
      .catch((error) => {
        console.log(error);
      })


    axios.get(config.get('backendURL') + 'homelayout/image/count/0')
      .then(response => {
        console.log(response)
        this.setState({ imagecount: response.data })
      })
      .catch((error) => {
        console.log(error);
      })


    axios.get(config.get('backendURL') + 'layouts/layout/' + sessionStorage.getItem('company'))
      .then(response => {
        this.setState({ datax: JSON.parse(response.data[0].itemdetails).sort((a, b) => (a.order - b.order)), slayoutid: response.data[0]._id })
      })
      .catch((error) => {
        console.log(error);
      })
 
      
      axios.get(config.get('backendURL') + 'themes/')
      .then(response => {
        console.log(response)
        this.setState({
          themes: response.data
        })
      })
      .catch(function (error) {
        console.log(error);
      })

  }
  state = { MenuVisible: false, CategoryVisible:false, ThemesVisible: false, ECDrawer: false, visible: false, ecommercevisible: false, widgetvisible: false, childrenDrawer: false, paymentDrawer: false, childrenDrawer1: false, SEODrawer: false, GADrawer: false, IUDrawer: false, UPDrawer: false, FBChatDrawer:false };

  showFilePagination = (data) => {

    let selected = data.selected;
    let offset = Math.ceil(selected * 12);

    axios.get(config.get('backendURL') + 'homelayout/image/get/' + offset)
      .then(response => {
        console.log(response.data)
        this.setState({ images: response.data })
      })
      .catch((error) => {
        console.log(error);
      })
  };

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };
  showecommerceDrawer = () => {
    this.setState({
      ecommercevisible: true,
    });
  };
  showewidgetDrawer = () => {
    this.setState({
      widgetvisible: true,
    });
  };
  showMenuDrawer = (itmmenuid, itmmenuname) => {
    this.setState({
      MenuVisible: true,
      MenuItemId: itmmenuid,
      MenuItem: itmmenuname,
    });
  };

  showCategoryDrawer = () => {
    this.setState({
      CategoryVisible: true 
    });
  };

  showThemes = () => {
    this.setState({
      ThemesVisible: true
    });
  };
  onThemesClose = () => {
    this.setState({
      ThemesVisible: false,
    });
  };
  onMenuClose = () => {
    this.setState({
      MenuVisible: false,
    });
  };
  
  onCategoryClose = () => {
    this.setState({
      CategoryVisible: false,
    });
  };
  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  onecommerceClose = () => {
    this.setState({
      ecommercevisible: false,
    });
  };
  onwidgetClose = () => {
    this.setState({
      widgetvisible: false,
    });
  };
  showChildrenDrawer = () => {
    this.setState({
      childrenDrawer: true,
    });
  };
  showPaymentDrawer = () => {
    this.setState({
      paymentDrawer: true,
    });
  };

  showFBChatDrawer =  () => {
    this.setState({
      FBChatDrawer: true,
    });
  };
  showMenu = () => {
    this.setState({
      childrenDrawer1: true,
    });
  };
  showSEO = () => {
    this.setState({
      SEODrawer: true,
    });
  };
  onSEODrawerClose = () => {
    this.setState({
      SEODrawer: false,
    });
  };
  showGA = () => {
    this.setState({
      GADrawer: true,
    });
  };
  showEC = (itmmenuid, itemshead) => {
    this.setState({
      ECDrawer: true,
      itmmenuid: itmmenuid,
      itemshead: itemshead,
    });
    // console.log(itmmenuid)
  };
  onECDrawerClose = () => {
    this.setState({
      ECDrawer: false,
    });
  };


  showIU = () => {
    axios.get(config.get('backendURL') + 'homelayout/image/get/0')
      .then(response => {
        this.setState({ images: response.data })
      })
      .catch((error) => {
        console.log(error);
      })

    this.setState({
      IUDrawer: true
    });

    // console.log(itmmenuid)
  };
  onIUDrawerClose = () => {
    this.setState({
      IUDrawer: false,
    });
  };
  getData(val, doid) {
    sessionStorage.setItem('uploadedmedia', val.data.imageName)
  }
  showUPDrawer = () => {
    this.setState({
      UPDrawer: true
    });
    // console.log(itmmenuid)
  };
  onUPDrawerClose = () => {
    this.setState({
      UPDrawer: false,
    });
    window.location.href = "/home"
  };

  onArticlesClick = (url) => {
    window.location.href = url
  };


  onGADrawerClose = () => {
    this.setState({
      GADrawer: false,
    });
  };

  onChildrenDrawerClose = () => {
    this.setState({
      childrenDrawer: false,
    });
  };
  onPaymentDrawerClose = () => {
    this.setState({
      paymentDrawer: false,
    });
  };
onFBChatDrawerClose = () => {
  this.setState({
    FBChatDrawer: false,
  });
};

  onChildrenDrawer1Close = () => {
    this.setState({
      childrenDrawer1: false,
    });
  };


  menuList() {
    return this.state.menus.map(currentpage => {
      return <Menu.Item onClick={() => this.showMenuDrawer(currentpage._id, currentpage.menuname)} key={currentpage._id}>{currentpage.menuname}</Menu.Item>;
    })
  }


  widgetsList() {
    return this.state.menus.map(currentpage => {
      return <Menu.Item onClick={() => this.showMenuDrawer(currentpage._id, currentpage.menuname)} key={currentpage._id}>{currentpage.menuname}</Menu.Item>;
    })
  }

  copyToClipboard(url) {

    copy(url);
    const placement = 'topLeft';
    notification.open({
      message: 'Copy To Clipboard',
      placement,
      description:
        'URL of the media copied to Clipboard',
      onClick: () => {
        console.log('Notification Clicked!');
      },

    });

  }

  render() {


    return (
      <Sider collapsible collapsed={this.state.collapsed} onCollapse={this.onCollapse}>
        <div className="logo" />
        <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline">


          <Menu.Item key="11" icon={<FormatPainterOutlined />}>
            <a href="/home">DESIGN</a>
          </Menu.Item>

          <SubMenu key="sub1" icon={<DesktopOutlined />} title="PAGES">

            {this.menuList()}

          </SubMenu>

          <SubMenu key="subq1" icon={<PushpinOutlined />} title="ARTICLES">

          <Menu.Item onClick={this.onArticlesClick.bind(this, '/articleslist')} key='ap'>All Articles</Menu.Item>
          <Menu.Item onClick={this.onArticlesClick.bind(this, '/new/article')} key='an'>Add New</Menu.Item>
          <Menu.Item onClick={() => this.showCategoryDrawer()} key='ac'>Categories</Menu.Item>
          </SubMenu>

          <Menu.Item key="widget" icon={<AppstoreAddOutlined />} onClick={this.showewidgetDrawer}>WIDGETS</Menu.Item>

          <Menu.Item key="ecommerce" icon={<BarcodeOutlined />} onClick={this.showecommerceDrawer}>E-COMMERCE</Menu.Item>

          <Menu.Item key="9" icon={<ToolOutlined />} onClick={this.showDrawer}>SETTINGS</Menu.Item>
        </Menu>


        <Drawer
          title={<div className="space-align-block">
            <Space align="center">

              <span className="mock-block"><ToolOutlined /></span>Settings
          </Space>
          </div>}
          width={320}
          placement="left"
          closable={true}
          onClose={this.onClose}
          visible={this.state.visible}
        >

          <ListGroup>
            <ListGroup.Item action variant="light" onClick={this.showChildrenDrawer}>
              COMPANY DETAILS
  </ListGroup.Item>
            <ListGroup.Item action variant="light" onClick={this.showPaymentDrawer}>
              PAYMENT SETTINGS
  </ListGroup.Item>

  <ListGroup.Item action variant="light" onClick={this.showFBChatDrawer}>
             CHAT SETTINGS
  </ListGroup.Item>


            <ListGroup.Item action variant="light" onClick={this.showMenu}>
              MENU ITEMS
  </ListGroup.Item>
            <ListGroup.Item action variant="light" onClick={this.showThemes}>
              THEMES
  </ListGroup.Item>
            <ListGroup.Item action variant="light" onClick={this.showSEO}>
              SEO
  </ListGroup.Item>
            <ListGroup.Item action variant="light" onClick={this.showIU}>
              MEDIA LIBRARY
  </ListGroup.Item>
            <ListGroup.Item action variant="light" onClick={this.showGA}>
              GOOGLE ANALYTICS
  </ListGroup.Item>
          </ListGroup>

          <Drawer
            title="COMPANY DETAILS"
            width={720}
            closable={true}
            placement="left"
            onClose={this.onChildrenDrawerClose}
            visible={this.state.childrenDrawer}
          >

            <CompanyDetails />
          </Drawer>

          <Drawer
            title="PAYMENT DETAILS"
            width={720}
            closable={true}
            placement="left"
            onClose={this.onPaymentDrawerClose}
            visible={this.state.paymentDrawer}
          >

            <PaymentDetails />
          </Drawer>
          <Drawer
            title="FACEBOOK CHAT DETAILS"
            width={720}
            closable={true}
            placement="left"
            onClose={this.onFBChatDrawerClose}
            visible={this.state.FBChatDrawer}
          >

            <FBChatDetails />
          </Drawer>


          <Drawer
            title="MENUS"
            width={720}
            closable={true}
            placement="left"
            onClose={this.onChildrenDrawer1Close}
            visible={this.state.childrenDrawer1}
          >
            <MenuItems />
          </Drawer>

          <Drawer
            title="SEO (Search Engine Optimization)"
            width={720}
            closable={true}
            placement="left"
            onClose={this.onSEODrawerClose}
            visible={this.state.SEODrawer}
          >

<SEODetails />

          </Drawer>


          <Drawer
            title={<div className="space-align-block">
              <Space align="center">
                <span className="mock-block"><ReadOutlined /> Themes</span>
              </Space>
            </div>}
            width={720}
            placement="left"
            closable={true}
            onClose={this.onThemesClose}
            visible={this.state.ThemesVisible}
          >



              {this.state.themes ? (
                this.state.themes.map((theme, index) => (
                  <>
                    {
                      theme.themename == this.state.Theme ? (
                        <p>{theme.themename}</p>
                      ) : (<p>{theme.themename}</p>)
                    }
                  </>
                ))
              ) : (<></>)
              }

          </Drawer>


          <Drawer
            title="MEDIA LIBRARY"
            width="100%"
            closable={true}
            placement="left"
            onClose={this.onIUDrawerClose}
            visible={this.state.IUDrawer}
          >


            <Button type="primary" onClick={this.showUPDrawer} >Upload Media</Button>

            <Row>

              {
                this.state.images.map(currentimage =>
                  <Col xs={2} md={2}>
                    <div className="site-card-border-less-wrapper">
                      <Card bordered={true} style={{ width: '100%' }}>
                        {
                          currentimage.meta_data.destination == 'images' ? (<>
                            {this.state.loaded ? null :
                              <div className="divLoader">
                                <LoadingOutlined style={{ fontSize: '56px', color: '#08c' }} /><br />
                              </div>
                            }
                            <img src={'https://cdn.neurolage.com/retailpacx/' + sessionStorage.getItem('companybrandname') + '/' + currentimage.meta_data.filename} onLoad={() => this.setState({ loaded: true })} style={this.state.loaded ? { width: '100%', height: 'auto', 'max-height': '100%', 'max-width': '100%' } : { display: 'none' }} class="contain" />
                          </>) : (<>
                            {this.state.loaded ? null :
                              <div className="divLoader">
                                <LoadingOutlined style={{ fontSize: '56px', color: '#08c' }} /><br />
                              </div>
                            }
                            <video autostart autoPlay loop muted="muted" src={'https://cdn.neurolage.com/retailpacx/' + sessionStorage.getItem('companybrandname') + '/' + currentimage.meta_data.filename} onLoad={() => this.setState({ loaded: true })} style={this.state.loaded ? { width: '100%' } : { display: 'none' }} ></video>
                          </>)
                        }
                        <input type="hidden" name="copy" value={'https://cdn.neurolage.com/retailpacx/' + sessionStorage.getItem('companybrandname') + '/' + currentimage.meta_data.filename} />
                        <button onClick={this.copyToClipboard.bind(this, 'https://cdn.neurolage.com/retailpacx/' + sessionStorage.getItem('companybrandname') + '/' + currentimage.meta_data.filename)} id="copy-to-clipboard" className="btn btn-normal">Copy to clipboard</button>

                      </Card></div><hr /></Col>
                )
              }

            </Row>
            <nav aria-label="Page navigation example">
              <ReactPaginate
                previousLabel={'Previous'}
                nextLabel={'Next'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={this.state.imagecount / 12}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={this.showFilePagination}
                containerClassName={'pagination'}
                pageClassName={'page-item'}
                pageLinkClassName={'page-link'}
                nextClassName={'page-link'}
                previousLinkClassName={'page-link'}
                activeClassName={'active'}
              />

            </nav>


            <Drawer
              title={this.state.itemheading}
              placement="top"
              closable={true}
              height={720}
              onClose={this.onUPDrawerClose}
              visible={this.state.UPDrawer}
              key="top"
            >

              <UploadItems sendData={this.getData} params={{ indexid: 0, itemid: '1212', MenuItemId: '1212', MenuItemData: '1212', ratiow: 0, ratioh: 0, width: 12, order: 0 }} />
            </Drawer>

          </Drawer>


          <Drawer
            title="Google Analytics"
            width={720}
            closable={true}
            placement="left"
            onClose={this.onGADrawerClose}
            visible={this.state.GADrawer}
          >

            GA
          </Drawer>

        </Drawer>

        <Drawer
          title={<div className="space-align-block">
            <Space align="center">

              <span className="mock-block"><BarcodeOutlined /></span>E-Commerce
          </Space>
          </div>}
          width={320}
          placement="left"
          closable={true}
          onClose={this.onecommerceClose}
          visible={this.state.ecommercevisible}
        >

          <Alert
            onClick={() => this.showEC('special', 'Products Page')}
            message="Products Page"
            description="Add products to landing page"
            type="info"
            showIcon
          />


          <Drawer
            title={this.state.itemshead}
            width={'100%'}
            closable={true}
            placement="left"
            onClose={this.onECDrawerClose}
            visible={this.state.ECDrawer}
          >




            <Products />

          </Drawer>


        </Drawer>

        <Drawer
          title={<div className="space-align-block">
            <Space align="center">

              <span className="mock-block"><AppstoreAddOutlined /></span>Widgets
          </Space>
          </div>}
          width={320}
          placement="left"
          closable={true}
          onClose={this.onwidgetClose}
          visible={this.state.widgetvisible}
        >
          <Row>
            <Col xs={6} md={6}>
              <Box
                boxShadow={3}
                bgcolor="background.paper"
                m={1}
                p={1}
                style={{ width: '8rem', height: '5rem' }}
              ><SlidersOutlined style={{ fontSize: '46px', color: '#08c' }} /><br />Carousal</Box>
            </Col>
            <Col xs={6} md={6}>
              <Box
                boxShadow={3}
                bgcolor="background.paper"
                m={1}
                p={1}
                style={{ width: '8rem', height: '5rem' }}
              > <FileImageOutlined style={{ fontSize: '46px', color: '#08c' }} /><br />Image</Box>
            </Col>
            <Col xs={6} md={6} className="text-center">
              <Box
                boxShadow={3}
                bgcolor="background.paper"
                m={1}
                p={1}
                style={{ width: '8rem', height: '5rem' }}
              > <VideoCameraAddOutlined style={{ fontSize: '46px', color: '#08c' }} /><br />Video</Box>
            </Col>
          </Row>

        </Drawer>
        <Drawer
          title={<div className="space-align-block">
            <Space align="center">
              <span className="mock-block"><ReadOutlined /></span>{this.state.MenuItem}
            </Space>
          </div>}
          width={320}
          placement="left"
          closable={true}
          onClose={this.onMenuClose}
          visible={this.state.MenuVisible}
        >


          <DragListViewMenuItems params={{ MenuItemId: this.state.MenuItemId }} />

        </Drawer>


        <Drawer
          title={<div className="space-align-block">
            <Space align="center">
              <span className="mock-block"><ReadOutlined /></span>Categories
            </Space>
          </div>}
          width={320}
          placement="left"
          closable={true}
          onClose={this.onCategoryClose}
          visible={this.state.CategoryVisible}
        >


<DragListViewCategoryItems />

        </Drawer>


      </Sider>
    );
  }
}