import React, { Component } from 'react';
import axios from 'axios';
import config from 'react-global-configuration';
 
 
export default class Company extends Component {
  constructor(props) {
    super(props);
 
  }
 
  componentDidMount() {

    const cachedCompany = sessionStorage.getItem('company');
    if (cachedCompany) {
      sessionStorage.setItem('company', this.props.match.params.id);
      
    } else {
      sessionStorage.setItem('company', this.props.match.params.id);
    }

    axios.get(config.get('backendURL') + 'company/' + sessionStorage.getItem('company'))
    .then(response => {
      sessionStorage.setItem('theme',response.data.theme)
      sessionStorage.setItem('companybrandname', response.data.CDNPath.replace(/ /g, '').replace('&', '').replace(/(^\&)|,/g, '').toLowerCase() );
     window.location.href="/home"
    })

 
  } 

  render() {
 
    return (
         <></>
    );
  }
}
