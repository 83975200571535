import React, { Component } from 'react';
import { Drawer } from 'antd';
import UploadItems from "./imageupload";
import VideoItems from "./videoupload";
import Carousel from "./carousel-upload";
import ItemPicks from "./item-picks";
import Gallery from "./gallery";
import Owlcarousel from "./owlcarousel";
import axios from 'axios';
import PImage from "./preview/image.component";
import PCarousel from "./preview/carousel";
import PItemPicks from "./preview/itempicks";
import PEditor from "./preview/editor";
import POwlcarousel from "./preview/owlcarousel";
import PVideo from "./preview/video";
import Contact from "./preview/contact"
import PBlog from "./preview/blog"
import Blog from "./blog"
import Map from "./map"
import PMap from "./preview/map"
import PGallery from "./preview/gallery";
import config from 'react-global-configuration';

require('dotenv').config()

export default class Application extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: ''
    };
  }
  componentDidMount() {
    axios.get(config.get('backendURL') + 'homelayout/byid/' + this.props.params.layoutid)
      .then(response => {
        //  console.log(response.data)
        this.setState({ datax: response.data })
      })
      .catch((error) => {
        console.log(error);
      })
  }

  showDrawer = (id, itemheading, itemdata, item, w, h, x, y, link, pageid, posi) => {
    this.setState({
      visible: true,
      itemheading: itemheading,
      itemdata: itemdata,
      item: item,
      itemid: id,
      w: w,
      h: h,
      x: x,
      y: y,
      link: link,
      pageid: pageid,
      posi:posi
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
    window.location.reload(false);
  };

  render() {
    const { visible } = this.state;
    return (
      <div className="container-fluid">

        {
          this.state.datax ? (
            <>
 
              <a href="javascript:void(0)" onClick={this.showDrawer.bind(this, this.state.datax._id, this.state.datax.itemheading, this.state.datax.itemdata, this.state.datax.item, this.state.datax.width, this.state.datax.height, this.state.datax.x, this.state.datax.y, this.state.datax.link, this.state.datax.pageid, this.state.datax.type == 'gallery' ? 'left' : 'top' )}>


                {
                  this.state.datax.type == 'image' ?
                    <PImage params={{ ItemData: this.state.datax.itemdata, className: 'col-sm-12' }} />
                    : ''
                }
                {
                  this.state.datax.type == 'carousel' ?
                    <PCarousel params={{ ItemHeading: this.state.datax.itemheading, ItemData: this.state.datax.itemdata }} />
                    : ''
                }
                {
                  this.state.datax.type == 'video' ?
                    <PVideo params={{ ItemHeading: this.state.datax.itemheading, ItemData: this.state.datax.itemdata }} />
                    : ''
                }



                {
                  this.state.datax.type == 'bestselling' ?
                    <PItemPicks params={{ ItemHeading: this.state.datax.itemheading, ItemData: this.state.datax.itemdata }} />

                    : ''
                }
                {
                  this.state.datax.type == 'featured' ?
                    <PItemPicks params={{ ItemHeading: this.state.datax.itemheading, ItemData: this.state.datax.itemdata }} />

                    : ''
                }
                {
                  this.state.datax.type == 'featuredoffers' ?
                    <PItemPicks params={{ ItemHeading: this.state.datax.itemheading, ItemData: this.state.datax.itemdata }} />

                    : ''
                }
                {
                  this.state.datax.type == 'offers' ?
                    <PItemPicks params={{ ItemHeading: this.state.datax.itemheading, ItemData: this.state.datax.itemdata }} />

                    : ''
                }

{
                  this.state.datax.type == 'owlcarousel' ?
                    <POwlcarousel params={{ id: this.state.datax.itemdata, ItemHeading: this.state.datax.itemheading, ItemData: this.state.datax.itemdata, w: this.state.datax.width, h: this.state.datax.height, x: this.state.datax.x, y: this.state.datax.y, fediter: true }} />

                    : ''
                }
                
                {
                  this.state.datax.type == 'gallery' ?
                    <PGallery params={{ id: this.state.datax.itemdata, ItemHeading: this.state.datax.itemheading, ItemData: this.state.datax.itemdata, w: this.state.datax.width, h: this.state.datax.height, x: this.state.datax.x, y: this.state.datax.y, fediter: true }} />

                    : ''
                }
                 {
                  this.state.datax.type == 'blog' ?
                    <PBlog params={{ id: this.state.datax.itemdata, ItemHeading: this.state.datax.itemheading, ItemData: this.state.datax.itemdata, w: this.state.datax.width, h: this.state.datax.height, x: this.state.datax.x, y: this.state.datax.y, fediter: true }} />

                    : ''
                }

                  </a>
                  {
                  this.state.datax.type == 'editor' ?
                    <PEditor params={{ id: this.state.datax.itemdata, ItemHeading: this.state.datax.itemheading, ItemData: this.state.datax.itemdata, w: this.state.datax.width, h: this.state.datax.height, x: this.state.datax.x, y: this.state.datax.y, fediter: true, passid:this.state.datax._id, pageid: this.state.datax.pageid }} />

                    : ''
                }
                {
                  this.state.datax.type == 'contact' ?
                    <Contact params={{ id: this.state.datax.itemdata, ItemHeading: this.state.datax.itemheading, ItemData: this.state.datax.itemdata, w: this.state.datax.width, h: this.state.datax.height, x: this.state.datax.x, y: this.state.datax.y, fediter: true }} />

                    : ''
                }
            {
                  this.state.datax.type == 'map' ?
                    <PMap params={{ id: this.state.datax.itemdata, ItemHeading: this.state.datax.itemheading, ItemData: this.state.datax.itemdata, w: this.state.datax.width, h: this.state.datax.height, x: this.state.datax.x, y: this.state.datax.y, fediter: true }} />

                    : ''
                }
            

              <Drawer
                title={this.state.itemheading}
                placement={this.state.posi}
                closable={true}
                height={720}
                width={800}
                onClose={this.onClose}
                visible={visible}
                key="top"
              >


                {
                  this.state.datax.type == 'image' ?
                    <UploadItems params={{ itemid: this.state.itemid, MenuItemId: this.state.itemheading, MenuItemData: this.state.itemdata, w: this.props.params.w, h: this.props.params.h, x: this.state.x, y: this.state.y, width: 12, order: 0, link: this.state.link, pageid: this.state.pageid }} />
                    : ''
                }
                {
                  this.state.datax.type == 'video' ?
                    <VideoItems params={{ itemid: this.state.itemid, MenuItemId: this.state.itemheading, MenuItemData: this.state.itemdata, MenuItem: this.state.item, w: this.state.w, h: this.state.h, x: this.state.x, y: this.state.y, width: 12, order: 0, pageid: this.state.pageid }} />
                    : ''
                }
                {
                  this.state.datax.type == 'carousel' ?
                    <Carousel params={{ itemid: this.state.itemid, MenuItemId: this.state.itemheading, MenuItemData: this.state.itemdata, w: this.state.w, h: this.state.h, x: this.state.x, y: this.state.y, pageid: this.state.pageid }} />
                    : ''
                }
                {
                  this.state.datax.type == 'bestselling' ?
                    <ItemPicks params={{ MenuItemId: this.state.itemheading, MenuItemData: this.state.itemdata, w: this.state.w, h: this.state.h, x: this.state.x, y: this.state.y, pageid: this.state.pageid }} />
                    : ''
                }
                {
                  this.state.datax.type == 'featured' ?
                    <ItemPicks params={{ MenuItemId: this.state.itemheading, MenuItemData: this.state.itemdata, w: this.state.w, h: this.state.h, x: this.state.x, y: this.state.y, pageid: this.state.pageid }} />
                    : ''
                }
                {
                  this.state.datax.type == 'featuredoffers' ?
                    <ItemPicks params={{ MenuItemId: this.state.itemheading, MenuItemData: this.state.itemdata, w: this.state.w, h: this.state.h, x: this.state.x, y: this.state.y, pageid: this.state.pageid }} />
                    : ''
                }
                {
                  this.state.datax.type == 'offers' ?
                    <ItemPicks params={{ MenuItemId: this.state.itemheading, MenuItemData: this.state.itemdata, w: this.state.w, h: this.state.h, x: this.state.x, y: this.state.y, pageid: this.state.pageid }} />
                    : ''
                }
                {
                  this.state.datax.type == 'owlcarousel' ?
                    <Owlcarousel params={{ id: this.state.itemid, ItemHeading: this.state.itemheading, ItemData: this.state.itemdata, w: this.state.w, h: this.state.h, x: this.state.x, y: this.state.y, fediter: false, pageid: this.state.pageid }} />
                    : ''
                }
                {
                  this.state.datax.type == 'editor' ?
                    <PEditor params={{ id: this.state.itemid, ItemHeading: this.state.itemheading, ItemData: this.state.itemdata, w: this.state.w, h: this.state.h, x: this.state.x, y: this.state.y, fediter: false, pageid: this.state.pageid }} />
                    : ''
                }
                {
                  this.state.datax.type == 'gallery' ?
                  <Gallery params={{ itemid: this.state.itemid, MenuItemId: this.state.itemheading, id:this.state.datax.itemdata, ItemHeading: this.state.datax.itemheading, ItemData: this.state.datax.itemdata, w: this.state.datax.width, h: this.state.datax.height, x: this.state.datax.x, y: this.state.datax.y,fediter:true, pageid: this.state.pageid  }} />
            
                    : ''
                }
                {
                  this.state.datax.type == 'contact' ?
                  <Contact params={{ id:this.state.datax.itemdata, ItemHeading: this.state.datax.itemheading, ItemData: this.state.datax.itemdata, w: this.state.datax.width, h: this.state.datax.height, x: this.state.datax.x, y: this.state.datax.y,fediter:true  }} />
            
                    : ''
                }
                {
                  this.state.datax.type == 'blog' ?
                  <Blog params={{id:this.state.datax._id,  itemid:this.state.datax.itemid, ItemHeading: this.state.datax.itemheading, ItemData: this.state.datax.itemdata, w: this.state.datax.width, h: this.state.datax.height, x: this.state.datax.x, y: this.state.datax.y,fediter:true  }} />
            
                    : ''
                }
                {
                  this.state.datax.type == 'map' ?
                  <Blog params={{id:this.state.datax._id,  itemid:this.state.datax.itemid, ItemHeading: this.state.datax.itemheading, ItemData: this.state.datax.itemdata, w: this.state.datax.width, h: this.state.datax.height, x: this.state.datax.x, y: this.state.datax.y,fediter:true  }} />
            
                    : ''
                }
              </Drawer>
            </>
          ) : (<></>)
        }
      </div>
    );
  }
}


