import React, { Component } from 'react';
import { Form, Col, Button } from 'react-bootstrap';
import { Modal, Drawer } from 'antd';
import axios from 'axios';
import UploadItems from "./imageupload-global";
import config from 'react-global-configuration';
import jwt from 'jsonwebtoken';

export default class Company extends Component {
  constructor(props) {
    super(props);
    this.onChangeName = this.onChangeName.bind(this);
    this.onChangeAddressLine1 = this.onChangeAddressLine1.bind(this);
    this.onChangeAddressLine2 = this.onChangeAddressLine2.bind(this);
    this.onChangeCity = this.onChangeCity.bind(this);
    this.onChangeStateProvinceRegion = this.onChangeStateProvinceRegion.bind(this);
    this.onChangePostZipCode = this.onChangePostZipCode.bind(this);
    this.onChangeCountry = this.onChangeCountry.bind(this);
    this.onChangePhone = this.onChangePhone.bind(this);
    this.onChangeFax = this.onChangeFax.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangeOpenningHours = this.onChangeOpenningHours.bind(this);
    this.onChangeSubDomain = this.onChangeSubDomain.bind(this);
    this.onChangeCustomerDomain = this.onChangeCustomerDomain.bind(this);
    this.onChangeOrganisationID = this.onChangeOrganisationID.bind(this);
    this.onChangeTheme = this.onChangeTheme.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    this.state = {
      Name: '',
      Logo: '',
      favicon:'',
      AddressLine1: '',
      AddressLine2: '',
      City: '',
      StateProvinceRegion: '',
      PostZipCode: '',
      Country: '',
      Phone: '',
      Fax: '',
      Email: '',
      OpenningHours: '',
      SubDomain: '',
      CustomerDomain: '',
      OrganisationID: '',
      ServiceKey: '',
      ClientKey: '',
      LogoCDN:'',
      LocationID:'',
      WishListEnabled:false,
      HelpPage:'',
      SocialMedia:'',
      DeliveryMessage:'',
      ShopNowItems:'',
      theme:'',
      FBappid:'',
      FBpageid:'',
      ratiow: '',
      ratioh: '',
      themes: [],
      CDNPath:'',
      SEOTitle: '',
      SEODescription: '',
      SEOKeywords: ''
    }
  }


  

  componentDidMount() {
    axios.get(config.get('backendURL') + 'company/' + sessionStorage.getItem('company'))
      .then(response => {
        this.setState({
          Name: response.data.Name,
          Logo: response.data.Logo,
          favicon: response.data.favicon,
          AddressLine1: response.data.AddressLine1,
          AddressLine2: response.data.AddressLine2,
          City: response.data.City,
          StateProvinceRegion: response.data.StateProvinceRegion,
          PostZipCode: response.data.PostZipCode,
          Country: response.data.Country,
          Phone: response.data.Phone,
          Fax: response.data.Fax,
          Email: response.data.Email,
          OpenningHours: response.data.OpenningHours,
          SubDomain: response.data.SubDomain,
          CustomerDomain: response.data.CustomerDomain,
          OrganisationID: response.data.OrganisationID,
          LogoCDN: response.data.LogoCDN,
          LocationID: response.data.LocationID,
          WorldPayServiceKey: response.data.WorldPayServiceKey,
          WorldPayClientKey: response.data.WorldPayClientKey,
          WishListEnabled: response.data.WishListEnabled,
          HelpPage: response.data.HelpPage,
          SocialMedia: response.data.SocialMedia,
          Theme: response.data.theme,
          DeliveryMessage: response.data.DeliveryMessage,
          ShopNowItems: response.data.ShopNowItems,
          FBappid:response.data.FBappid,
          FBpageid:response.data.FBpageid,
          CDNPath:response.data.CDNPath,
          SEOTitle:response.data.SEOTitle,
          SEODescription:response.data.SEODescription,
          SEOKeywords:response.data.SEOKeywords
        })



        

        
        this.state.themes.map((theme, index) => (
          theme.themename == this.state.Theme ? (
            theme.ratio.split(':').map((themesss) => (this.setState({
              ratiow: themesss[0],
              ratioh: themesss[1],
            })))
          ) : (
              this.setState({
                ratiow: '',
                ratioh: '',
              })
            )

        ))

      })
      .catch(function (error) {
        console.log(error);
      })



    axios.get(config.get('backendURL') + 'themes/')
      .then(response => {
        console.log(response)
        this.setState({
          themes: response.data
        })
      })
      .catch(function (error) {
        console.log(error);
      })
  }
  showDrawer = (id, itemheading, itemdata) => {
    this.setState({
      visible: true
    });
  };
  showfaviconDrawer = (id, itemheading, itemdata) => {
    this.setState({
      faviconvisible: true
    });
  };
  onChangeName(e) {
    this.setState({
      Name: e.target.value
    })
  }
  onChangeAddressLine1(e) {
    this.setState({
      AddressLine1: e.target.value
    })
  }
  onChangeAddressLine2(e) {
    this.setState({
      AddressLine2: e.target.value
    })
  }
  getData(val, doid) {
    sessionStorage.setItem('uploadedimage', val.data.imageName)
  }
  getfaviconData(val, doid) {
    sessionStorage.setItem('faviconimage', val.data.imageName)
  }
  onChangeCity(e) {
    this.setState({
      City: e.target.value
    })
  }
  onChangeStateProvinceRegion(e) {
    this.setState({
      StateProvinceRegion: e.target.value
    })
  }
  onChangePostZipCode(e) {
    this.setState({
      PostZipCode: e.target.value
    })
  }
  onChangeCountry(e) {
    this.setState({
      Country: e.target.value
    })
  }
  onChangePhone(e) {
    this.setState({
      Phone: e.target.value
    })
  }
  onChangeFax(e) {
    this.setState({
      Fax: e.target.value
    })
  }
  onChangeEmail(e) {
    this.setState({
      Email: e.target.value
    })
  }
  onChangeOpenningHours(e) {
    this.setState({
      OpenningHours: e.target.value
    })
  }
  onChangeSubDomain(e) {
    this.setState({
      SubDomain: e.target.value
    })
  }
  onChangeCustomerDomain(e) {
    this.setState({
      CustomerDomain: e.target.value
    })
  }
  onChangeTheme(e) {
    this.setState({
      Theme: e.target.value
    })


  }
  onChangeOrganisationID(e) {
    this.setState({
      OrganisationID: e.target.value
    })
  }
  onClose = () => {
    this.setState({
      Logo: sessionStorage.getItem('uploadedimage'),
      visible: false,
    });
  };
  onfaviconClose = () => {
    this.setState({
      favicon: sessionStorage.getItem('faviconimage'),
      faviconvisible: false,
    });
  };
  onSubmit(e) {
    e.preventDefault();

    const company = {
      Name: this.state.Name,
      OrganisationID: this.state.OrganisationID,
      Logo: this.state.Logo,
      favicon: this.state.favicon,
      AddressLine1: this.state.AddressLine1,
      AddressLine2: this.state.AddressLine2,
      City: this.state.City,
      StateProvinceRegion: this.state.StateProvinceRegion,
      PostZipCode: this.state.PostZipCode,
      Country: this.state.Country,
      Phone: this.state.Phone,
      Fax: this.state.Fax,
      Email: this.state.Email,
      OpenningHours: this.state.OpenningHours,
      SubDomain: this.state.SubDomain,
      CustomerDomain: this.state.CustomerDomain,
      LogoCDN: this.state.LogoCDN,
      LocationID: this.state.LocationID,
      WorldPayServiceKey: this.state.WorldPayServiceKey,
      WorldPayClientKey: this.state.WorldPayClientKey,
      WishListEnabled: this.state.WishListEnabled,
      HelpPage: this.state.HelpPage,
      SocialMedia: this.state.SocialMedia,
      theme: this.state.Theme,
      DeliveryMessage: this.state.DeliveryMessage,
      ShopNowItems: this.state.ShopNowItems,
      FBappid:this.state.FBappid,
      FBpageid:this.state.FBpageid,
      CDNPath:this.state.CDNPath,
      SEOTitle:this.state.SEOTitle,
      SEODescription:this.state.SEODescription,
      SEOKeywords:this.state.SEOKeywords
    }
    const timestamp = Math.floor(Date.now() / 1000) + 1000;
    var payload = {
      "aud": process.env.REACT_APP_jwtAud,
      "exp": timestamp,
      "iss": process.env.REACT_APP_jwtIss
    };

    var token = jwt.sign(payload, process.env.REACT_APP_secret);

    const requestOptions = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ` + token,
      }
    };
    axios.post(config.get('backendURL') + 'company/update/' + sessionStorage.getItem('company'), company, requestOptions)
      .then(function (data) {

        Modal.success({
          title: 'Success',
          content: 'Company details has been updated',
          onOk() { },
        });


      })

    //window.location = '/';
  }

  render() {
    const { visible, faviconvisible } = this.state;
    return (
      <Form onSubmit={this.onSubmit}>
        <Form.Row>
          <Form.Group as={Col} controlId="formGridEmail">
            <Form.Label>Company Name</Form.Label>
            <Form.Control required type="text" value={this.state.Name} onChange={this.onChangeName} placeholder="Company Name" />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} controlId="formGridPassword">

            <Button variant="primary" onClick={this.showDrawer.bind(this)} type="button">
              Upload Logo
  </Button>


          </Form.Group>
          <Form.Group as={Col} controlId="formfavicon">
            <Button variant="primary" onClick={this.showfaviconDrawer.bind(this)} type="button">
              Upload favicon
</Button>
          </Form.Group>
        </Form.Row>

        <Form.Group controlId="formGridAddress1">
          <Form.Label>Address</Form.Label>
          <Form.Control value={this.state.AddressLine1} onChange={this.onChangeAddressLine1} />
        </Form.Group>

        <Form.Group controlId="formGridAddress2">
          <Form.Label>Address 2</Form.Label>
          <Form.Control value={this.state.AddressLine2} onChange={this.onChangeAddressLine2} />
        </Form.Group>

        <Form.Row>
          <Form.Group as={Col} controlId="formGridCity">
            <Form.Label>City</Form.Label>
            <Form.Control value={this.state.City} onChange={this.onChangeCity} />
          </Form.Group>
          <Form.Group as={Col} controlId="formGridState">
            <Form.Label>State</Form.Label>
            <Form.Control value={this.state.StateProvinceRegion} onChange={this.onChangeStateProvinceRegion} />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} controlId="formGridZip">
            <Form.Label>Zip</Form.Label>
            <Form.Control value={this.state.PostZipCode} onChange={this.onChangePostZipCode} />
          </Form.Group>
          <Form.Group as={Col} controlId="formGridCountry">
            <Form.Label>Country</Form.Label>
            <Form.Control value={this.state.Country} onChange={this.onChangeCountry} />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} controlId="formGridPhone">
            <Form.Label>Phone</Form.Label>
            <Form.Control value={this.state.Phone} onChange={this.onChangePhone} />
          </Form.Group>
          <Form.Group as={Col} controlId="formGridFax">
            <Form.Label>Fax</Form.Label>
            <Form.Control value={this.state.Fax} onChange={this.onChangeFax} />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} controlId="formGridEmail">
            <Form.Label>Email</Form.Label>
            <Form.Control type="email" value={this.state.Email} onChange={this.onChangeEmail} />
          </Form.Group>
          <Form.Group as={Col} controlId="formGridFax">
            <Form.Label>Openning Hours</Form.Label>
            <Form.Control value={this.state.OpenningHours} onChange={this.onChangeOpenningHours} />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} controlId="formGridSubDomain">
            <Form.Label>Sub Domain</Form.Label>
            <Form.Control value={this.state.SubDomain} onChange={this.onChangeSubDomain} />
          </Form.Group>
          <Form.Group as={Col} controlId="formGridCustomerDomain">
            <Form.Label>Customer Domain</Form.Label>
            <Form.Control value={this.state.CustomerDomain} onChange={this.onChangeCustomerDomain} />
          </Form.Group>
        </Form.Row>

        <Form.Row>
          <Form.Group as={Col} controlId="formTheme">
            <Form.Label>Theme</Form.Label>

            <Form.Control as="select" onChange={this.onChangeTheme}>



              {this.state.themes ? (
                this.state.themes.map((theme, index) => (
                  <>
                    {
                      theme.themename == this.state.Theme ? (
                        <option value={theme.themename} selected>{theme.themename}</option>
                      ) : (<option value={theme.themename}>{theme.themename}</option>)
                    }
                  </>
                ))
              ) : (<></>)
              }



            </Form.Control>

          </Form.Group>
        </Form.Row>


        <Form.Row>
          <Form.Group as={Col} controlId="formGridOrgId">
            <Form.Label>Organisation ID</Form.Label>
            <Form.Control value={this.state.OrganisationID} onChange={this.onChangeOrganisationID} />
          </Form.Group>
        </Form.Row>
        <Button variant="primary" type="submit">
          Submit
  </Button>

        <Drawer
          title={this.state.itemheading}
          placement="top"
          closable={true}
          height={720}
          onClose={this.onClose}
          visible={visible}
          key="top"
        >

          <UploadItems sendData={this.getData} params={{ indexid: 0, itemid: this.state.itemid, MenuItemId: this.state.itemheading, MenuItemData: this.state.itemdata, ratiow: this.state.ratiow, ratioh: this.state.ratioh, width: 12, order: 0 }} />
        </Drawer>
        <Drawer
          title={this.state.itemheading}
          placement="top"
          closable={true}
          height={720}
          onClose={this.onfaviconClose}
          visible={faviconvisible}
          key="top"
        >

          <UploadItems sendData={this.getfaviconData} params={{ indexid: 0, itemid: this.state.itemid, MenuItemId: this.state.itemheading, MenuItemData: this.state.itemdata, ratiow: 1, ratioh: 1, width: 12, order: 0 }} />
        </Drawer>


      </Form>


    );
  }
}
